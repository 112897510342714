<template>
	<GlobalNotification />
	<ZPrompt
		v-if="isMaintenanceTime"
		:open="isPromptOpen"
		:isTruncated="false"
		@close="isPromptOpen = false"
	>
		<span v-html="locale.SCHEDULED_MAINTENANCE" />
	</ZPrompt>
	<router-view />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useNetworkConnection, ZPrompt } from '@zoomcatalog/design-system'
import { GlobalNotification } from '@/components/Feedback'
import { useNotification } from './composable/useNotification'

const locale = {
	CHECK_NETWORK_CONNECTION: 'Check your network connection',
	BACK_ONLINE: 'You are back online',
	SCHEDULED_MAINTENANCE:
		'<b>Scheduled Maintenance</b> <br /> Our product search will be down for scheduled maintenance on (11/4) from 05:00 PM to 06:00 PM PDT',
}

const scheduledMaintenance = {
	startTime: new Date('2022-11-03T00:00:00-07:00').getTime(),
	endTime: new Date('2022-11-04T20:00:00-07:00').getTime(),
}

export default defineComponent({
	name: 'App',
	components: {
		GlobalNotification,
		ZPrompt,
	},
	setup() {
		const { enqueueNotification } = useNotification()

		const isPromptOpen = ref(true)
		const isMaintenanceTime = checkScheduledMaintenance()
		function checkScheduledMaintenance(): boolean {
			const today = new Date().getTime()
			return (
				today >= scheduledMaintenance.startTime &&
				today <= scheduledMaintenance.endTime
			)
		}

		useNetworkConnection({
			onOnlineChange: (isOnline) => {
				if (isOnline) return

				enqueueNotification({
					message: locale.CHECK_NETWORK_CONNECTION,
					color: 'danger',
				})
			},
			onBackOnlineChange: () => {
				enqueueNotification({
					message: locale.BACK_ONLINE,
					color: 'success',
				})
			},
		})
		return { locale, isPromptOpen, isMaintenanceTime }
	},
})
</script>

<style lang="scss">
body {
	@apply bg-gray-50;
}
</style>
