import { computed, ComputedRef, InjectionKey } from 'vue'
import {
	createLogger,
	createStore,
	Plugin,
	Store,
	useStore as baseUseStore,
} from 'vuex'

import { createAutoSavePlugin } from '@/store/plugins/autoSave/autoSavePlugin'
import { createPaginationPlugin } from '@/store/plugins/pagination/paginationPlugin'

import { AuthMutations, authStore, AuthStore } from './modules/auth'
import {
	AutoSaveMutations,
	autoSaveStore,
	AutoSaveStore,
} from './modules/autoSave'
import {
	DesignSettingsMutations,
	designSettingsStore,
	DesignSettingsStore,
} from './modules/designSettings'
import {
	NotificationMutations,
	notificationStore,
	NotificationStore,
} from './modules/notification'
import { PageMutations, pageStore, PageStore } from './modules/page'
import { ProjectMutations, projectStore, ProjectStore } from './modules/project'
import {
	SingleSetupStore,
	singleSetupStore,
	SingleSetupMutations,
} from './modules/singleSetup'
import { SnifferMutations, snifferStore, SnifferStore } from './modules/sniffer'

export interface RootStore {
	auth: AuthStore
	project: ProjectStore
	page: PageStore
	designSettings: DesignSettingsStore
	autoSave: AutoSaveStore
	notification: NotificationStore
	sniffer: SnifferStore
	singleSetup: SingleSetupStore
}

export const rootStore = createStore<RootStore>({
	state: undefined,
	mutations: {},
	actions: {},
	modules: {
		auth: authStore,
		project: projectStore,
		page: pageStore,
		designSettings: designSettingsStore,
		autoSave: autoSaveStore,
		notification: notificationStore,
		sniffer: snifferStore,
		singleSetup: singleSetupStore,
	},
	plugins: [
		import.meta.env.MODE === 'development' &&
			createLogger<RootStore>({
				mutationTransformer(mutation) {
					return {
						type: mutation.type,
						payload: (mutation as any)?.payload?.payload,
					}
				},
			}),
		import.meta.env.MODE !== 'development' &&
			createAutoSavePlugin({
				socketUrl: import.meta.env.VITE_API_SOCKET as string,
				enableLogger: import.meta.env.MODE === 'development',
			}),
		createPaginationPlugin(),
	].filter(Boolean) as Plugin<RootStore>[],
})

export const key: InjectionKey<Store<RootStore>> = Symbol()

export function useStore() {
	return baseUseStore(key)
}

export type StoreMutations =
	| AuthMutations
	| ProjectMutations
	| DesignSettingsMutations
	| PageMutations
	| SnifferMutations
	| AutoSaveMutations
	| NotificationMutations
	| SingleSetupMutations

export function useCommit() {
	return (action: StoreMutations) => rootStore.commit(action)
}

export type StoreActions = any

export function useDispatch() {
	return async (action: StoreActions) => rootStore.dispatch(action)
}

function useBaseSelector<TState = Record<string, unknown>, TSelected = unknown>(
	selector: (state: TState) => TSelected
): ComputedRef<TSelected>
function useBaseSelector(selector: (state: any) => any): any {
	return computed(() => selector(rootStore.state))
}

export type TypedUseSelector<TState> = <TSelected>(
	selector: (state: TState) => TSelected
) => ComputedRef<TSelected>

/**
 * A hook to access the vuex store's state. This hook takes a selector function
 * as an argument. The selector is called with the store state.
 *
 * @param selector the selector function
 *
 * @returns the selected state
 *
 * @example
 *
 * const counter = useSelector((state) => state.counter)
 */
export const useSelector: TypedUseSelector<RootStore> = useBaseSelector
