import { computed, unref } from 'vue'
import useAxios from '@/composable/RequestUtils/useAxios'
import {
	AUTH_POST_REFRESH_TOKEN,
	SSO_POST_TOKEN_EXCHANGE,
} from '@/config/endpoints'
import { useSelector } from '@/store/rootStore'

const endpoints = useSelector((state) => state.sniffer.endpoints)

export const useRefreshToken = () => {
	const { loading, hasData, response, errors, post } = useAxios()
	const data = computed(() => (hasData.value ? unref(response).data : {}))
	const userToken = computed(() =>
		hasData.value ? unref(data).user_token : null
	)
	const refreshToken = computed(() =>
		hasData.value ? unref(data).refresh_token : null
	)

	const requestToken = async (payload: Record<string, unknown>) => {
		const { endpoint } = AUTH_POST_REFRESH_TOKEN
		try {
			await post(endpoint, payload, {
				baseURL: endpoints.value.AUTH_API_ENDPOINT,
			})
			return Promise.resolve(data)
		} catch (error) {
			errors.value = error as any
			return Promise.reject(unref(errors.value))
		}
	}
	return {
		userToken,
		refreshToken,
		loading,
		hasData,
		requestToken,
	}
}

export const useTokenExchange = () => {
	const { loading, hasData, response, errors, post } = useAxios()
	const data = computed(() => (hasData.value ? unref(response).data : {}))
	const userToken = computed(() =>
		hasData.value ? unref(data).user_token : null
	)
	const refreshToken = computed(() =>
		hasData.value ? unref(data).refresh_token : null
	)

	const requestTokenExchange = async (payload: Record<string, unknown>) => {
		try {
			await post(SSO_POST_TOKEN_EXCHANGE.endpoint, payload, {
				baseURL: endpoints.value.SSO_API_ENDPOINT,
			})
			return Promise.resolve(data)
		} catch (error) {
			errors.value = error as any
			return Promise.reject(unref(errors.value))
		}
	}
	return {
		userToken,
		refreshToken,
		loading,
		hasData,
		requestTokenExchange,
	}
}

export default {}
