const QuickCanvas = () =>
	import(/* webpackChunkName: "quick-canvas" */ '@/views/QuickCanvas.vue')

export default [
	{
		path: '/:id?',
		name: 'editor',
		component: QuickCanvas,
		meta: {
			requiresAuth: true,
			checkForCompanyRestrictions: true,
			requiresCompanyInfo: true,
		},
		children: [],
	},
]
