import { createApp } from 'vue'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import router from '@/router'

export function setupSentry(app: ReturnType<typeof createApp>) {
	if (!['development', 'testing'].includes(import.meta.env.MODE)) {
		Sentry.init({
			app,
			environment: import.meta.env.MODE as string,
			dsn: import.meta.env.VITE_SENTRY_DSN as string,
			integrations: [
				new Integrations.BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
					tracingOrigins: ['localhost', 'services.zoomcatalog.com', /^\//],
				}),
			],
			debug: false,
			// tracesSampleRate: import.meta.env.MODE === 'production' ? 0.2 : 1,
			tracingOptions: {
				trackComponents: true,
			},
			// Vue specific
			logErrors: import.meta.env.MODE !== 'production',
			attachProps: true,
			attachStacktrace: true,
			beforeBreadcrumb(breadcrumb, hint) {
				if (breadcrumb.category === 'ui.click' && hint) {
					const { target } = hint.event
					if (target.getAttribute('data-cy')) {
						breadcrumb.message = target.getAttribute('data-cy')
					}
				}
				return breadcrumb
			},
		})
	}
}
