declare global {
	interface Window {
		analytics: {
			load: (key: string) => void
			track: (title: string, paylaod: Record<string, unknown>) => void
			identify: (userId: string, paylaod: Record<string, unknown>) => void
		}
	}
}

export default function useAnalytics() {
	const track = (title: string, payload?: Record<string, unknown>) => {
		window.analytics.track(title, {
			product: 'quick_canvas',
			...payload,
		})
	}
	const identify = (userId: string, payload?: Record<string, unknown>) => {
		window.analytics.identify(userId, {
			...payload,
		})
	}
	return {
		track,
		identify,
	}
}
