import { QuickCanvasModel } from '@/store/interfaces'
import { useCommit, useSelector } from '@/store/rootStore'

export function useProjectStore() {
	const commit = useCommit()

	const projectData = useSelector((state) => state.project.data)
	const projectIsModified = useSelector(
		(state) => state.project.data.created_at !== state.project.data.updated_at
	)

	function setProject(project: QuickCanvasModel) {
		commit({ type: 'project/setProject', payload: project })
		commit({ type: 'page/setBasicPages', payload: project.pages })
	}

	function updateProject(project: Partial<QuickCanvasModel>) {
		commit({ type: 'project/setProject', payload: project })
	}

	function makeDraft() {
		commit({ type: 'project/setProject', payload: { is_draft: true } })
	}

	return {
		projectData,
		projectIsModified,

		setProject,
		updateProject,
		makeDraft,
	}
}
