// PROJECT ENDPOINTS
export const PROJECT_POST = { method: 'post', endpoint: 'project' }
export const PROJECT_GET_ALL = { method: 'get', endpoint: 'projects' }
export const PROJECT_GET_BY_ID = {
	method: 'get',
	endpoint: (id: string | string[]) => `project/${id}`,
}
export const PROJECT_PATCH = {
	method: 'patch',
	endpoint: (id: string | string[]) => `project/${id}`,
}

export const PROJECT_CHECK_LOCKED = {
	method: 'head',
	endpoint: (id: string | string[]) => `project/${id}`,
}

// PROJECT PAGE ENDPOINTS
export const PROJECT_PAGES_GET_ALL = {
	method: 'get',
	endpoint: (id: string) => `project/${id}/pages`,
}

// DOWNLOAD ENDPOINTS
export const PROJECT_DOWNLOAD_CREATE = {
	method: 'post',
	endpoint: (id: string, format: string) => `project/${id}/download/${format}`,
}
export const PROJECT_DOWNLOAD_UPDATE = {
	method: 'put',
	endpoint: (id: string, jobId: string) => `project/${id}/download/${jobId}`,
}
export const PROJECT_DOWNLOADS_PENDING = {
	method: 'get',
	endpoint: (id: string) => `project/${id}/downloads/pending`,
}

// PRODUCT ENDPOINTS
export const PRODUCTS_GET_BY_KEYWORD = {
	method: 'get',
	endpoint: '/products',
}
export const PRODUCT_GET_BY_ID = {
	method: 'post',
	endpoint: (id: string) => `product/${id}/details`,
}
export const GET_PRODUCT_ATTRIBUTES_LIST = {
	method: 'get',
	endpoint: (productAttribute: string) =>
		`/product-attribute/${productAttribute}/list`,
}

// AUTH ENDPOINTS
export const AUTH_POST_REFRESH_TOKEN = {
	method: 'post',
	endpoint: 'auth/authorize',
}
export const SSO_POST_TOKEN_EXCHANGE = {
	method: 'post',
	endpoint: 'auth/authorize/token/exchange',
}

// USER ENDPOINTS
export const USER_GET = {
	method: 'get',
	endpoint: 'user',
}

// DISTRIBUTOR ENDPOINTS

export const GET_DISTRIBUTOR_USER = {
	method: 'get',
	endpoint: (username: string) =>
		`user/${username}/company/relationships/distributor`,
}

// ASSET LIBRARY ENDPOINTS
export const GET_ASSET_LIBRARY = {
	method: 'get',
	endpoint: (tags: string[] = []) => `/asset/tag/${tags.join('+')}`,
}

export const GET_ASSET_LIBRARY_SEARCHABLE = {
	method: 'get',
	endpoint: `/assets`,
}

// COMPANY ENDPOINTS
export const COMPANY_INFO_GET = {
	method: 'get',
	endpoint: `company/info`,
}

export const GET_BRAND_SETTINGS = {
	method: 'get',
	endpoint: `company/brand`,
}

export const GET_COMPANY_SETTINGS = {
	method: 'get',
	endpoint: 'company/settings',
}

export const GET_USER_COMPANIES = {
	method: 'get',
	endpoint: 'user/companies',
}

export const GET_CURRENCIES = {
	method: 'get',
	endpoint: `currencies?sort=weight`,
}

export const GET_COMPANY_CURRENCIES = {
	method: 'get',
	endpoint: `company/currency`,
}

// PUBLISH ENDPOINTS
export const GET_PUBLISH_DESIGN = {
	method: 'get',
	endpoint: (id: string) => `project/${id}/publish`,
}

export const POST_PUBLISH_DESIGN = {
	method: 'post',
	endpoint: (id: string) => `project/${id}/publish`,
}

export const PATCH_PUBLISH_DESIGN = {
	method: 'patch',
	endpoint: (id: string) => `project/${id}/publish`,
}

// LAYOUTS ENDPOINTS
export const LAYOUTS_ENDPOINTS = {
	get: 'layouts',
	getLayout: (layoutId: string) => `layout/${layoutId}`,
}

export default {}
