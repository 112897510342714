import {
	useRefreshToken,
	useTokenExchange,
} from '@/composable/RequestUtils/useAuthorization'
import useAxios from '@/composable/RequestUtils/useAxios'
import useAnalytics from '@/composable/useAnalytics'
import { settings } from '@/settings/DynamicSettings'
import { IUser } from '@/store/interfaces'
import { useCommit, useSelector } from '@/store/rootStore'
import { storageInstances } from '@/utils/storageIntances'

export function useAuthStore() {
	const commit = useCommit()
	const isAuthenticated = useSelector((state) => state.auth.isAuthorized)
	const isTeamMember = useSelector((state) => state.auth.isTeamMember)
	const role = useSelector((state) => state.auth.role)
	const company = useSelector((state) => state.auth.company)

	const setAuthorization = (token: string, refreshToken: string) => {
		const { setAuthHeader, setRefreshTokenInterceptor } = useAxios()
		setAuthHeader(token)
		setRefreshTokenInterceptor()
		storageInstances.userToken.set(token)
		storageInstances.refreshToken.set(refreshToken)
		commit({ type: 'auth/setAuthorization' })
	}

	const setDistributorId = (distributorId: string) => {
		storageInstances.distributorStorage.set(distributorId)

		commit({
			type: 'auth/setDistributorId',
			payload: distributorId,
		})

		commit({
			type: 'auth/setIsPersonalSpace',
			payload: distributorId === settings.PERSONAL_SPACE_ID,
		})
	}

	const setIsTeamMember = (isTeamMember: boolean) => {
		commit({
			type: 'auth/setIsTeamMember',
			payload: isTeamMember,
		})
	}

	const setRole = (role: string) => {
		commit({
			type: 'auth/setRole',
			payload: role,
		})
	}

	const removeAuthorization = () => {
		const { removeAuthHeader, removeRefreshTokenInterceptor } = useAxios()
		removeAuthHeader()
		removeRefreshTokenInterceptor()
		storageInstances.userToken.remove()
		storageInstances.refreshToken.remove()
		commit({ type: 'auth/removeAuthorization' })
	}

	const setNewAuthorization = (token: string, refreshToken: string) => {
		const { setAuthHeader } = useAxios()
		setAuthHeader(token)
		storageInstances.userToken.set(token)
		storageInstances.refreshToken.set(refreshToken)
		commit({ type: 'auth/setAuthorization' })
	}

	const identifyUserAnalytics = async (user: IUser | null) => {
		if (!user) return

		const { identify } = useAnalytics()
		identify(user.id, {
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email,
			role: role.value,
			company: company.value?.slug,
		})
	}

	const isAuthorized = async (temporalSupplierToken?: string) => {
		const previousToken =
			temporalSupplierToken ?? storageInstances.refreshToken.get()
		try {
			if (isAuthenticated.value) {
				return true
			} else if (previousToken) {
				const { userToken, refreshToken, requestToken } = useRefreshToken()
				await requestToken({
					refresh_token: previousToken,
					grant_type: 'refresh_token',
					client_id: import.meta.env.VITE_CRM_CLIENT_ID,
				})
				handleDistributorId(Boolean(temporalSupplierToken))
				setAuthorization(userToken.value, refreshToken.value)
			}
			return isAuthenticated.value
		} catch (err) {
			window.location.href = import.meta.env.VITE_LOGIN_URL as string
		}
	}

	const tokenExchange = async (
		sessionId: string,
		supplierId: string,
		distributorId?: string
	) => {
		const { userToken, refreshToken, requestTokenExchange } = useTokenExchange()
		if (distributorId) {
			setDistributorId(distributorId)
		}
		await requestTokenExchange({
			sid: sessionId,
			supplier_id: supplierId,
			...(distributorId ? { distributor_id: distributorId } : {}),
		})
		setAuthorization(userToken.value, refreshToken.value)
	}

	const handleDistributorId = (hasSupplierToken: boolean) => {
		const distributorId = storageInstances.distributorStorage.get()

		if (hasSupplierToken) {
			storageInstances.distributorStorage.remove()
		} else if (distributorId) {
			setDistributorId(distributorId)
		}
	}

	return {
		setAuthorization,
		setNewAuthorization,
		setDistributorId,
		setIsTeamMember,
		setRole,
		removeAuthorization,
		identifyUserAnalytics,
		tokenExchange,
		isAuthorized,
		company,
		isTeamMember,
		role,
		isAuthenticated,
	}
}
