import { createApi } from 'unsplash-js'
// eslint-disable-next-line import/no-unresolved
import { ApiResponse as UnsplashApiResponse } from 'unsplash-js/dist/helpers/response'
// eslint-disable-next-line import/no-unresolved
import { Basic as BasicPhoto } from 'unsplash-js/dist/methods/photos/types'

export type UnsplashClient = ReturnType<typeof createApi>
export type UnsplashGetPhotos = UnsplashClient['search']['getPhotos']
export type UnsplashTrackDownlaod = UnsplashClient['photos']['trackDownload']
export type UnsplashPhotoResponse = UnsplashResponse<UnsplashPhoto>
export type UnsplashError = { code: number; message: string }

export interface UnsplashResponse<A> {
	results: A[]
	total: number
	total_pages: number
}

export interface UnsplashPhoto extends BasicPhoto {
	tags: {
		title: string
		type: string
	}[]
}

export interface UnsplashAssetModel {
	name: string
	id: string
	created_at: string
	updated_at: string
	url: string
	user: {
		id: string
		first_name: string
		last_name: string
		username: string
	}
	download_location: string
	tags: string[]
}

export interface UnsplashAssetResponseModel {
	res: UnsplashAssetModel[]
	total_available: number
}

class unsplashClientInstance {
	#client: UnsplashClient

	constructor() {
		this.#client = createApi({
			accessKey: import.meta.env.VITE_UNSPLASH_KEY,
		})
	}

	private getResponseError<T>(
		response: UnsplashApiResponse<T>
	): UnsplashError | undefined {
		if (!response.errors) return
		return {
			code: response.status,
			message: response.errors.join(','),
		}
	}

	private makeImageModel(
		apiResponse: UnsplashPhotoResponse
	): UnsplashAssetResponseModel {
		const res = apiResponse.results.reduce((assets, asset) => {
			return [
				...assets,
				{
					name: asset.alt_description ?? 'unnamed',
					id: asset.id,
					created_at: asset.created_at,
					updated_at: asset.updated_at,
					url: asset.urls.raw,
					user: {
						id: asset.user.id,
						first_name: asset.user.first_name ?? '',
						last_name: asset.user.last_name ?? '',
						username: asset.user.username,
					},
					tags: (asset.tags ?? []).map((tag) => tag.title),
					download_location: asset.links.download_location,
				},
			]
		}, [] as UnsplashAssetResponseModel['res'])
		return {
			res,
			total_available: apiResponse.total,
		}
	}

	async searchPhotos(
		...args: Parameters<UnsplashGetPhotos>
	): Promise<UnsplashAssetResponseModel> {
		const response = await this.#client.search.getPhotos(...args)
		if (response.type === 'error') {
			throw this.getResponseError<UnsplashPhotoResponse>(response)
		}
		const imageModel = this.makeImageModel(
			response.response as UnsplashPhotoResponse
		)
		return imageModel
	}

	async trackDownload(
		...args: Parameters<UnsplashTrackDownlaod>
	): Promise<{ url: string }> {
		const response = await this.#client.photos.trackDownload(...args)
		if (response.type === 'error') {
			throw this.getResponseError<UnsplashPhotoResponse>(response)
		}
		return response.response
	}

	getResizeUrl(rawUrl: string, size: [string] | [string, string]) {
		return `${rawUrl}&w=${size[0]}&h=${size[1] ?? ''}`
	}
}

const unsplashClient = new unsplashClientInstance()

export default unsplashClient
