import { createApp } from 'vue'
import '@zoomcatalog/design-system/dist/main.css'
import '@zoomcatalog/core-ui/dist/main.css'
import '@zoomcatalog/design-templates/dist/index.css'
import '@/scss/styles.scss'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { useSnifferServices } from '@zoomcatalog/composable'
import App from './App.vue'
import api, { setBaseUrl } from './config/axios'
import { setupSentry } from './config/sentry'
import router from './router'
import { ENV, settings } from './settings/DynamicSettings'
import { rootStore, useCommit } from './store/rootStore'

const app = createApp(App)
setupApp(app).then(() => {
	app.use(router).use(rootStore).use(VueQueryPlugin).mount('#app')
})

async function setupApp(app: ReturnType<typeof createApp>) {
	setupSentry(app)
	setupAnalytics()

	if (settings.ENABLE_MSW) {
		const { worker } = await import('./mocks/browser')
		worker.start({ onUnhandledRequest: 'bypass' })
		setBaseUrl(`${window.origin}/api`)
	} else {
		await setupSniffer()
	}
}

function setupAnalytics() {
	if (import.meta.env.VITE_USE_ANALYTICS === 'true') {
		window.analytics.load(import.meta.env.VITE_SEGMENT_KEY as string)
	}
}

async function setupSniffer() {
	const commit = useCommit()

	const environment = import.meta.env.MODE as ENV
	if (environment !== 'development') {
		const snifferService = await useSnifferServices({
			useDefaultEndpoints: true,
			httpGetRequest: api.get,
			snifferQuery: {
				environment: environment === 'production' ? 'prod' : environment,
				servicesList: [
					'companies',
					'assets',
					'helpers',
					'quickcanvas',
					'users',
					'auth',
					'sso',
					'virtual-sample',
				],
			},
		})
		commit({
			type: 'sniffer/setServicesEndpoints',
			payload: snifferService.baseUrls,
		})

		setBaseUrl(`${snifferService.baseUrls.QC_API_ENDPOINT}canvas/v1`)
	}
}
