import { ref, computed, reactive } from 'vue'
import { makeInstanceFactory } from '@zoomcatalog/shared'
import type { BasicPage, Page } from '@/store/interfaces'

enum SelectionDirection {
	UP = 'up',
	DOWN = 'down',
}

const [
	useMakePageSelectionInstance,
	usePageSelectionInstance,
	usePageSelectionHandlers,
] = makeInstanceFactory('page-selection', () => {
	const pages = ref<Page[]>([])
	const limit = ref(20)
	const hasPages = computed(() => Boolean(pages.value.length))
	const lastPage = computed(() => pages.value.reverse()[0])

	/**
	 * Handlers
	 */
	function select(selectedPages: Page[]) {
		pages.value.push(...selectedPages)
	}

	function remove(selectedPages: Page[]) {
		selectedPages.forEach((selectedPage) => {
			const index = pages.value.findIndex(
				(page) => selectedPage.page_id === page.page_id
			)
			pages.value.splice(index, 1)
		})
	}

	function clear() {
		pages.value = []
	}

	function pageExists(selectedPage: Page | BasicPage) {
		return pages.value.some((page) => selectedPage.page_id === page.page_id)
	}

	function filterPagesSelectionDown(allPages: Page[], selectedPage: BasicPage) {
		const isSpreadPage = Boolean(selectedPage.spread_page)
		return allPages.filter(
			(page) =>
				page.index >= lastPage.value.index &&
				page.index <=
					(isSpreadPage ? selectedPage.index + 1 : selectedPage.index) &&
				!pageExists(page)
		)
	}

	function filterPagesSelectionUp(allPages: Page[], selectedPage: BasicPage) {
		const isSpreadPage = Boolean(selectedPage.spread_page)
		return allPages.filter(
			(page) =>
				page.index <= lastPage.value.index &&
				page.index >=
					(isSpreadPage ? selectedPage.index - 1 : selectedPage.index) &&
				!pageExists(page)
		)
	}

	function getSelectionDirection(selectedPage: Page | BasicPage) {
		return selectedPage.index > lastPage.value.index
			? SelectionDirection.DOWN
			: SelectionDirection.UP
	}

	return [
		reactive({
			pages,
			limit,
			hasPages,
			lastPage,
		}),
		{
			select,
			remove,
			clear,
			pageExists,
			filterPagesSelectionDown,
			filterPagesSelectionUp,
			getSelectionDirection,
		},
	]
})

export {
	useMakePageSelectionInstance,
	usePageSelectionInstance,
	usePageSelectionHandlers,
}
