import { Module } from 'vuex'
import { ICompanySettings } from '@/composable/RequestUtils/useCompanySettingsQuery'
import { ICurrency } from '@/composable/RequestUtils/useCurrencies'
import { GetPagesByProjectIdResponse } from '@/composable/RequestUtils/usePage'
import { EventPayload } from '@/ts-definitions'
import { Company, IDistributor, IUser, QuickCanvasModel } from '../interfaces'
import { RootStore } from '../rootStore'

export type SingleSetupStore = {
	data: {
		company_info?: Company
		currencies?: ICurrency[]
		distributor?: IDistributor[]
		settings?: ICompanySettings
		user?: IUser
		project?: QuickCanvasModel
		project_pages?: GetPagesByProjectIdResponse
	} | null
}

type SingleSetupNamespace = 'singleSetup'

type SingleSetupDataMutation = EventPayload<
	`${SingleSetupNamespace}/setSingleSetupData`,
	Partial<SingleSetupStore['data']>
>

export type SingleSetupMutations = SingleSetupDataMutation

export const singleSetupStore: Module<SingleSetupStore, RootStore> = {
	namespaced: true,
	state: {
		data: null,
	},
	mutations: {
		setSingleSetupData(state, { payload }: SingleSetupDataMutation) {
			state.data = payload
		},
	},
}
