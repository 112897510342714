import { computed } from 'vue'
import { DesignSettingsStore } from '@/store/modules/designSettings'
import { useCommit, useSelector } from '@/store/rootStore'

export function useDesignSettingsStore() {
	const commit = useCommit()
	const settings = useSelector((state) => state.designSettings)
	const hasSettings = computed(() => !!settings.value.name)

	const setTitle = (title: string) => {
		document.title = `${title} - ${import.meta.env.VITE_TITLE}`
	}

	const setDesignSettings = (payload: DesignSettingsStore) => {
		setTitle(payload.name)
		commit({ type: 'designSettings/setSettings', payload })
	}

	return {
		settings,
		hasSettings,
		setDesignSettings,
	}
}
