export const SYSTEM_ALERT = [400, 500]
export const COMMON_ERRORS = [400, 429, 500]
export const AUTH = [401]
export const UNPROCESSABLE = [422]
export const ACCESS = [503]
export const THROTTLE = [429]
export default {
	SYSTEM_ALERT,
	COMMON_ERRORS,
	AUTH,
	UNPROCESSABLE,
	ACCESS,
	THROTTLE,
}
