import { toRefs, ref, computed, reactive } from 'vue'
import { formatNewProductPricing } from '@zoomcatalog/design-templates'
import type { Page as NormalizedPage } from '@zoomcatalog/design-templates'
import { makeInstanceFactory } from '@zoomcatalog/shared'
import { useDesignStatus } from '@/components/DesignSettings/useDesignStatus'
import { settings } from '@/settings/DynamicSettings'
import {
	defaultFourProductsLayoutPage,
	defaultImageLayoutPage,
} from '@/settings/PageSettings'
import type { BasicPage, Page, ProductNestedData } from '@/store/interfaces'
import { useActivePageRef } from '@/store/plugins/pagination/paginationPlugin'
import { rootStore, useCommit, useSelector } from '@/store/rootStore'
import { useProjectStore } from './StoreUtils/useProjectStore'

export type PaginationInstanceProps = {
	initialPageNumber: number
}

enum pageOffset {
	singlePage = 1,
	spreadPage = 2,
}

enum range {
	lower = 0,
	higher = 1,
}

export function getNormalizedPage(
	page: Page,
	currency = 'USD',
	priceTableAttributes?: Record<string, boolean>
): NormalizedPage {
	const priceLevel =
		rootStore.state.auth.company?.slug === settings.SLUGS.SHOWDOWNDISPLAYS
			? 'highest'
			: 'lowest'

	return {
		width: page.width,
		height: page.height,
		pageId: page.page_id,
		layoutId: page.layout_id,
		index: page.index,
		title: page.title || '',
		coverImage: page.cover_image || '',
		products: Object.values(page.products).reduce((products, product) => {
			const { data } = product as unknown as ProductNestedData
			const productData = data ?? product

			if (!product) return {}
			return {
				...products,
				[product.slot]: formatNewProductPricing(
					productData,
					currency,
					priceTableAttributes,
					priceLevel
				),
			}
		}, {}),
		logos: page.logos,
		settings: {
			feature: {
				...page.settings?.feature,
			},
			products: {
				...page.settings?.products,
			},
			sections: {
				...page.settings?.sections,
			},
			template: {
				...page.settings?.template,
			},
		},
		layout: page.layout,
		templateName: page.layout.name,
	}
}

const [
	useMakePaginationInstance,
	usePaginationInstance,
	usePaginationHandlers,
] = makeInstanceFactory('pagination', (props: PaginationInstanceProps) => {
	const commit = useCommit()
	const { initialPageNumber } = toRefs(props)
	const { projectData } = useProjectStore()
	const designStatus = useDesignStatus()
	const { afterDomUpdate, scrollActivePageIntoView } = useActivePageRef()
	const queryLimit = ref(15)
	const hasFetchedAll = ref(false)
	const fetchBeforeMount = ref(false)
	const pendingToMount = ref<Page[]>([])
	const stackRange = ref<[number, number]>([0, 0])
	const fetchMorePages = ref<(() => void) | undefined>()
	const currentPageNumber = ref(initialPageNumber) // the actual page number, not the array index

	const pages = useSelector((state) => state.page.pages)
	const pageList = computed(() => Object.values(pages.value))
	const basicPages = useSelector((state) => state.page.basicPages)
	const basicPageList = computed(() => Object.values(basicPages.value))
	const totalPages = computed(() => basicPageList.value.length)
	const currentPages = computed(() => {
		const pageData = pages.value[currentPageNumber.value]
		const pageSettings = pageData?.settings

		if (pageSettings?.feature?.spreadPage) {
			return [
				pages.value[currentPageNumber.value],
				pages.value[
					pageSettings.feature.spreadPage === 'left'
						? currentPageNumber.value + 1
						: currentPageNumber.value - 1
				],
			]
				.filter((maybePage): maybePage is Page => Boolean(maybePage))
				.sort((a, b) => a.index - b.index)
		}
		return [pages.value[currentPageNumber.value]].filter(Boolean)
	})
	const currentBasicPages = computed(() => {
		const basicPageData = basicPages.value[currentPageNumber.value]

		if (basicPageData?.spread_page) {
			return [
				basicPages.value[currentPageNumber.value],
				basicPages.value[
					basicPageData.spread_page === 'left'
						? currentPageNumber.value + 1
						: currentPageNumber.value - 1
				],
			].sort((a, b) => a.index - b.index)
		}
		return [basicPages.value[currentPageNumber.value]]
	})
	const numberOfPages = computed(() => pageList.value.length)
	const hasPages = computed(() => Boolean(numberOfPages.value))
	const canGoNext = computed(() => currentPageNumber.value < numberOfPages.value)
	const canGoPrevious = computed(() => currentPageNumber.value > 1)
	const isSpreadPage = computed(() => currentPages.value.length > 1)
	const isLastPageSpreadPage = computed(() => {
		const pageData = pages.value[numberOfPages.value]
		if (!pageData) return false
		const pageSettings = pageData.settings

		return Boolean(pageSettings?.feature?.spreadPage)
	})

	/**
	 * Handlers
	 */
	function setPages(pages: Page[]) {
		commit({ type: 'page/setPages', payload: pages })
	}
	const addPages = (pages: Page[]) => {
		commit({ type: 'page/addPages', payload: pages })
	}

	function makeEmptyPage(index: number) {
		const defaultPageBody = (() => {
			if (designStatus.isUploaded.value) {
				const lastPage = pageList.value[pageList.value.length - 1]

				return {
					...defaultImageLayoutPage,
					width: lastPage.width,
					height: lastPage.height,
				}
			}

			return defaultFourProductsLayoutPage
		})()

		return {
			...defaultPageBody,
			project_id: projectData.value.project_id,
			page_id: `${Date.now().toString(16)}${index}`,
			index,
		}
	}

	function copyExistingPage(arrayIndex: number, pageIndex?: number) {
		return {
			...currentPages.value[arrayIndex],
			index: pageIndex || arrayIndex,
			page_id: `${Date.now().toString(16)}${pageIndex || arrayIndex}`,
		}
	}

	function goToPage(pageNumber: number) {
		currentPageNumber.value = pageNumber
	}

	function goToNextPage() {
		const stepSize = currentPages.value.length
		if (isSpreadPage.value) {
			goToPage(Math.min(...currentPages.value.map((page) => page.index)))
		}
		const nextPageIndex = currentPageNumber.value + stepSize
		if (nextPageIndex > numberOfPages.value) return
		goToPage(nextPageIndex)
		afterDomUpdate(scrollActivePageIntoView)
	}

	function goToPrevPage() {
		const stepSize = currentPages.value.length
		if (isSpreadPage.value) {
			goToPage(Math.max(...currentPages.value.map((page) => page.index)))
		}
		const nextPageIndex = currentPageNumber.value - stepSize
		if (!(nextPageIndex > 0)) return
		goToPage(nextPageIndex)
		afterDomUpdate(scrollActivePageIntoView)
	}

	function addFirstPage(type: 'layout' | 'save') {
		if (type === 'layout') {
			const newPage = makeEmptyPage(numberOfPages.value + pageOffset.singlePage)
			addPages([newPage])
		} else {
			const page = copyExistingPage(1)
			setPages([])
			addPage('single', page)
		}
	}

	function addPage(
		type: 'single' | 'spread',
		data?: Page | { leftPage: Page; rightPage: Page }
	) {
		if (type === 'single') {
			const newPage = makeEmptyPage(totalPages.value + pageOffset.singlePage)
			commit({
				type: 'page/addPage',
				payload: { ...newPage, ...(data as Extract<Page, typeof data>) },
			})
			currentPageNumber.value = newPage.index
		} else {
			const leftPage = makeEmptyPage(totalPages.value + pageOffset.singlePage)
			const rightPage = makeEmptyPage(totalPages.value + pageOffset.spreadPage)

			commit({
				type: 'page/addPage',
				payload: {
					...leftPage,
					...(data as Exclude<typeof data, Page>)?.leftPage,
					settings: {
						feature: {
							spreadPage: 'left',
						},
					},
				},
			})
			commit({
				type: 'page/addPage',
				payload: {
					...rightPage,
					...(data as Exclude<typeof data, Page>)?.rightPage,
					settings: {
						feature: {
							spreadPage: 'right',
						},
					},
				},
			})
			currentPageNumber.value = leftPage.index
		}
	}

	function duplicatePage() {
		const sourcePages = currentPages.value
		const copiedCurrentPages = sourcePages.map((page, index) =>
			copyExistingPage(index, page.index + sourcePages.length)
		)
		const lastCurrentPageIndex = copiedCurrentPages.length - 1
		currentPageNumber.value = copiedCurrentPages[lastCurrentPageIndex].index
		commit({
			type: 'page/duplicatePage',
			payload: { pages: copiedCurrentPages, sourcePages },
		})
	}

	function deletePage(page: Page) {
		if (totalPages.value <= 1) {
			addPage('single')
		}
		commit({ type: 'page/deletePage', payload: page })
		currentPageNumber.value = getClosestIndex(page)
	}

	function deleteMultiplePages(selectedPages: Page[], lastSelectedPage?: Page) {
		commit({
			type: 'page/deleteMultiplePages',
			payload: selectedPages,
		})

		currentPageNumber.value = getClosestIndex(
			lastSelectedPage ?? selectedPages[0]
		)

		if (!totalPages.value) {
			addPage('single')
		}
	}

	function getClosestIndex(page: Page) {
		return pageList.value.reduce(
			(prevIndex, currPage) =>
				Math.abs(currPage.index - page.index) < Math.abs(prevIndex - page.index)
					? currPage.index
					: prevIndex,
			0
		)
	}

	function updatePagesIndexes(pages: Page[]) {
		commit({ type: 'page/updatePagesIndexes', payload: pages })
	}

	function reorderSpreadPages() {
		if (!isSpreadPage.value) return

		const leftPage = { ...currentPages.value[0] }
		const rightPage = { ...currentPages.value[1] }

		if (
			!leftPage.settings?.feature?.spreadPage ||
			!rightPage.settings?.feature?.spreadPage
		)
			return

		const nextLeftPage: Page = {
			...leftPage,
			index: rightPage.index,
			settings: {
				...leftPage.settings,
				feature: {
					...leftPage.settings.feature,
					spreadPage: 'right',
				},
			},
		}

		const nextRightPage: Page = {
			...rightPage,
			index: leftPage.index,
			settings: {
				...rightPage.settings,
				feature: {
					...rightPage.settings.feature,
					spreadPage: 'left',
				},
			},
		}

		commit({
			type: 'page/reorderSpreadPages',
			payload: { leftPage: nextLeftPage, rightPage: nextRightPage },
		})
		commit({
			type: 'page/updatePageMedia',
			payload: { pageId: leftPage.page_id },
		})
		commit({
			type: 'page/updatePageMedia',
			payload: { pageId: rightPage.page_id },
		})
	}

	function pageExists(page: Page | BasicPage): boolean {
		return Boolean(pages.value[page.index])
	}

	function addNextPagesBatch(newPages: Page[]) {
		if (!newPages) return
		if (newPages.some(pageExists)) {
			const matchingBasicPages = basicPageList.value.filter((basicPage) =>
				newPages.some((newPage) => newPage.page_id === basicPage.page_id)
			)
			const reindexedPages = matchingBasicPages.map((basicPage, index) => ({
				...newPages[index],
				index: basicPage.index,
			}))
			addPages(reindexedPages)
		} else {
			addPages(newPages)
		}
	}

	function setFetchMorePages(fetchMorePagesFn: () => void) {
		fetchMorePages.value = fetchMorePagesFn
	}

	function fetchAllPages() {
		if (hasFetchedAll.value) return
		fetchBeforeMount.value = true
		fetchMorePages.value?.()
	}

	return [
		reactive({
			range,
			queryLimit,
			totalPages,
			stackRange,
			fetchBeforeMount,
			pendingToMount,
			hasFetchedAll,
			pages,
			currentPageNumber,
			currentPages,
			currentBasicPages,
			numberOfPages,
			hasPages,
			canGoNext,
			canGoPrevious,
			isSpreadPage,
			isLastPageSpreadPage,
			pageList,
			basicPageList,
			fetchMorePages,
			basicPages,
		}),
		{
			setPages,
			addPages,
			goToPage,
			goToNextPage,
			goToPrevPage,
			addFirstPage,
			addPage,
			duplicatePage,
			deletePage,
			deleteMultiplePages,
			updatePagesIndexes,
			reorderSpreadPages,
			pageExists,
			addNextPagesBatch,
			setFetchMorePages,
			getNormalizedPage,
			fetchAllPages,
		},
	]
})

export {
	useMakePaginationInstance,
	usePaginationInstance,
	usePaginationHandlers,
}
