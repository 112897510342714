import { Module } from 'vuex'
import { Event, EventPayload } from '@/ts-definitions'
import { Company } from '../interfaces'
import { RootStore } from '../rootStore'

export interface AuthStore {
	isAuthorized: boolean
	company?: Company
	distributorId: string | null
	isTeamMember: boolean
	isPersonalSpace: boolean
	role?: string
}

type AuthNamespace = 'auth'

type SetAuthorizationMutation = Event<`${AuthNamespace}/setAuthorization`>
type RemoveAuthorizationMutation = Event<`${AuthNamespace}/removeAuthorization`>
type SetCompanyMutation = EventPayload<`${AuthNamespace}/setCompany`, Company>
type SetDistributorIdMutation = EventPayload<
	`${AuthNamespace}/setDistributorId`,
	string | null
>
type SetIsTeamMemberMutation = EventPayload<
	`${AuthNamespace}/setIsTeamMember`,
	boolean
>
type SetIsPersonalSpaceMutation = EventPayload<
	`${AuthNamespace}/setIsPersonalSpace`,
	boolean
>

type SetRoleMutation = EventPayload<`${AuthNamespace}/setRole`, string>

export type AuthMutations =
	| SetAuthorizationMutation
	| RemoveAuthorizationMutation
	| SetCompanyMutation
	| SetDistributorIdMutation
	| SetIsTeamMemberMutation
	| SetIsPersonalSpaceMutation
	| SetRoleMutation

export const authStore: Module<AuthStore, RootStore> = {
	namespaced: true,
	state: {
		isAuthorized: false,
		distributorId: null,
		company: undefined,
		isTeamMember: false,
		isPersonalSpace: false,
		role: undefined,
	},
	mutations: {
		setAuthorization(state) {
			state.isAuthorized = true
		},
		removeAuthorization(state) {
			state.isAuthorized = false
		},
		setCompany(state, { payload }: SetCompanyMutation) {
			state.company = payload
		},
		setDistributorId(state, { payload }: SetDistributorIdMutation) {
			state.distributorId = payload
		},
		setIsTeamMember(state, { payload }: SetIsTeamMemberMutation) {
			state.isTeamMember = payload
		},
		setIsPersonalSpace(state, { payload }: SetIsPersonalSpaceMutation) {
			state.isPersonalSpace = payload
		},
		setRole(state, { payload }: SetRoleMutation) {
			state.role = payload
		},
	},
}
