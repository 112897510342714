<script setup lang="ts">
import { reactive } from 'vue'
import { useSetupServices } from '@/components/CanvasContainer/useSetupServices'
import { useMakeAutoSaveInstance } from '@/composable/useAutoSave'
import { useMakePageSelectionInstance } from '@/composable/usePageSelection'
import { useMakePaginationInstance } from '@/composable/usePagination'
import { useMakePaginationFixerInstance } from '@/composable/usePaginationFixer'

useMakePaginationInstance(
	reactive({
		initialPageNumber: 1,
	})
)
useMakePageSelectionInstance()
useMakeAutoSaveInstance()
useMakePaginationFixerInstance()
useSetupServices()
</script>

<template>
	<div class="main">
		<router-view />
	</div>
</template>

<style>
.main {
	@apply h-full overflow-hidden;
}
</style>
