interface Settings {
	ENABLE_MSW: boolean
	TEMPLATE_TUTORIAL: string
	PERSONAL_SPACE_ID: string
	SLUGS: {
		ARIELPREMIUM: string
		PCNA: string
		DANDEEINT: string
		SHOWDOWNDISPLAYS: string
		A4: string
		AMROD: string
		MOOZ: string
	}
	QC_UI_DEVELOPMENT_URL(slug: string): string
	SINGLE_SETUP_URL(projectId?: string): string
}

export type ENV = 'development' | 'alpha' | 'beta' | 'production'

const sharedKeys = {
	TEMPLATE_TUTORIAL: 'https://www.youtube.com/watch?v=V0LEfloVkkc',
	ENABLE_MSW: false,
	PERSONAL_SPACE_ID: '2222222222222222222',
	SLUGS: {
		ARIELPREMIUM: 'arielpremium',
		PCNA: 'pcna',
		DANDEEINT: 'dandeeint',
		SHOWDOWNDISPLAYS: 'showdowndisplays',
		A4: 'a4',
		AMROD: 'amrod',
		MOOZ: 'mooz',
	},
}

export const VUE_APP: Record<ENV, Settings> = {
	development: {
		...sharedKeys,
		ENABLE_MSW: true,
		QC_UI_DEVELOPMENT_URL: (slug: string) =>
			`alpha-${slug}-quickcanvas.zoomcatalog.local`,
		SINGLE_SETUP_URL(projectId?: string) {
			if (projectId) {
				return `https://alpha-quickcanvas-services.zoomcatalog.com/canvas/v1/project/${projectId}/editor?debug=true`
			}

			return `https://alpha-quickcanvas-services.zoomcatalog.com/canvas/v1/project/editor?debug=true`
		},
	},

	alpha: {
		...sharedKeys,
		QC_UI_DEVELOPMENT_URL: (slug: string) =>
			`alpha-${slug}-quickcanvas.zoomcatalog.local`,
		SINGLE_SETUP_URL(projectId?: string) {
			if (projectId) {
				return `https://alpha-quickcanvas-services.zoomcatalog.com/canvas/v1/project/${projectId}/editor?debug=true`
			}

			return `https://alpha-quickcanvas-services.zoomcatalog.com/canvas/v1/project/editor?debug=true`
		},
	},

	beta: {
		...sharedKeys,
		QC_UI_DEVELOPMENT_URL: (slug: string) =>
			`beta-${slug}-quickcanvas.zoomcatalog.local`,
		SINGLE_SETUP_URL(projectId?: string) {
			if (projectId) {
				return `https://beta-quickcanvas-services.zoomcatalog.com/canvas/v1/project/${projectId}/editor?debug=true`
			}

			return `https://beta-quickcanvas-services.zoomcatalog.com/canvas/v1/project/editor?debug=true`
		},
	},

	production: {
		...sharedKeys,
		QC_UI_DEVELOPMENT_URL: (slug: string) =>
			`${slug}-quickcanvas.zoomcatalog.local`,
		SINGLE_SETUP_URL(projectId?: string) {
			if (projectId) {
				return `https://prod-quickcanvas-services.zoomcatalog.com/canvas/v1/project/${projectId}/editor?debug=true`
			}

			return `https://prod-quickcanvas-services.zoomcatalog.com/canvas/v1/project/editor?debug=true`
		},
	},
}

export const settings: Settings =
	VUE_APP[(import.meta.env?.MODE as ENV) ?? 'development']
