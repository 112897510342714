import { computed, toRef } from 'vue'
import { useProjectStore } from '@/composable/StoreUtils/useProjectStore'

export function useDesignStatus() {
	const { projectData } = useProjectStore()

	const isUploaded = toRef(() => Boolean(projectData.value.is_uploaded))
	const isTemplate = computed(() => projectData.value.is_template)
	const isDraft = computed(() => projectData.value.is_draft)
	const isPublished = computed(() => projectData.value.is_published)
	const hasParentTemplate = computed(() => Boolean(projectData.value.parent))

	return {
		isUploaded,
		isTemplate,
		isDraft,
		isPublished,
		hasParentTemplate,
	}
}
