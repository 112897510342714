import { Module } from 'vuex'

import type { Event, EventPayload } from '@/ts-definitions'
import type { RootStore } from '../rootStore'

export interface NotificationStore {
	open: boolean
	message?: string
	color?: 'primary' | 'success' | 'warning' | 'danger'
	anchor?: {
		vertical: 'top' | 'center' | 'bottom'
		horizontal: 'left' | 'center' | 'right'
	}
	delay?: number
	closable?: boolean
}

const initialState: NotificationStore = {
	open: false,
	message: undefined,
	color: 'success',
	anchor: {
		vertical: 'top',
		horizontal: 'center',
	},
	delay: 5000,
	closable: true,
}

export type NotificationMutations = EnqueueNotification | CloseNotification

export type EnqueueNotification = EventPayload<
	'notification/enqueueNotification',
	Omit<NotificationStore, 'open'>
>

type CloseNotification = Event<'notification/closeNotification'>

export const notificationStore: Module<NotificationStore, RootStore> = {
	namespaced: true,
	state: () => ({ ...initialState }),
	mutations: {
		enqueueNotification: (state, { payload }: EnqueueNotification) => {
			state = Object.assign(state, initialState)
			state = Object.assign(state, payload)
			state.open = true
		},
		closeNotification: (state) => {
			state = Object.assign(state, { ...initialState, color: state.color })
			state.open = false
		},
	},
}
