import { ref } from 'vue'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import api, {
	setAuthHeader,
	removeAuthHeader,
	setRefreshTokenInterceptor,
	removeRefreshTokenInterceptor,
} from '@/config/axios'

export default function useAxios<T = any>() {
	// data
	const loading = ref<boolean>(false),
		hasData = ref<boolean>(false)
	const response = ref<AxiosResponse<T> | Record<string, never>>({}),
		errors = ref<AxiosError | Record<string, never>>({})

	const handleAxiosResponse = async <T extends AxiosResponse>(
		axiosResponse: T
	) => {
		try {
			response.value = axiosResponse as any
			loading.value = false
			hasData.value = Object.keys(<T>response.value.data).length > 0
			return Promise.resolve(axiosResponse)
		} catch (error) {
			errors.value = error as any
			loading.value = false
			hasData.value = false
			return Promise.reject(errors.value)
		}
	}

	// api methods
	const get = async (endpoint: string, config?: AxiosRequestConfig) => {
		loading.value = true
		return handleAxiosResponse<AxiosResponse<T>>(await api.get(endpoint, config))
	}

	const post = async (
		endpoint: string,
		payload: Record<string, unknown>,
		config?: AxiosRequestConfig
	) => {
		loading.value = true
		return handleAxiosResponse<AxiosResponse<T>>(
			await api.post(endpoint, payload, config)
		)
	}

	const put = async (
		endpoint: string,
		payload: Record<string, unknown>,
		config?: AxiosRequestConfig
	) => {
		loading.value = true
		return handleAxiosResponse<AxiosResponse<T>>(
			await api.put(endpoint, payload, config)
		)
	}

	const patch = async (
		endpoint: string,
		payload: Record<string, unknown>,
		config?: AxiosRequestConfig
	) => {
		loading.value = true
		return handleAxiosResponse<AxiosResponse<T>>(
			await api.patch(endpoint, payload, config)
		)
	}

	const resetData = () => {
		response.value = {}
		loading.value = false
		hasData.value = false
		errors.value = {}
	}

	return {
		loading,
		hasData,
		response,
		errors,
		get,
		post,
		put,
		patch,
		resetData,
		setAuthHeader,
		removeAuthHeader,
		setRefreshTokenInterceptor,
		removeRefreshTokenInterceptor,
	}
}
