import type { Page } from '@zoomcatalog/design-templates'
import type { MutationExtraOptions } from '@/store/modules/page'
import { useCommit, useSelector } from '@/store/rootStore'

export function usePageSettingsStore() {
	const commit = useCommit()
	const pages = useSelector((state) => state.page.pages)

	function setPageSettings(
		settings: Page['settings'],
		page: Page,
		options?: MutationExtraOptions
	) {
		commit({
			type: 'page/setPageSettings',
			payload: { page, settings, options },
		})
	}
	function updateProductSettings(
		settings: Page['settings']['products'],
		page: Page
	) {
		commit({
			type: 'page/updateProductSettings',
			payload: { page, settings },
		})
	}
	function updatePageMedia(pageId: Page['pageId']) {
		const pageList = Object.values(pages.value)
		const matchingPage = pageList.find((page) => page.page_id === pageId)

		if (matchingPage?.settings?.feature?.spreadPage === 'right') {
			const leftPage = pageList.find(
				(page) => page.index === matchingPage.index - 1
			)
			if (leftPage?.page_id) {
				return commit({
					type: 'page/updatePageMedia',
					payload: { pageId: leftPage.page_id },
				})
			}
		}

		return commit({
			type: 'page/updatePageMedia',
			payload: { pageId },
		})
	}

	return {
		setPageSettings,
		updateProductSettings,
		updatePageMedia,
	}
}
