import * as Sentry from '@sentry/vue'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRefreshToken } from '@/composable/RequestUtils/useAuthorization'
import { useAuthStore } from '@/composable/StoreUtils/useAuthStore'
import { useNotification } from '@/composable/useNotification'
import { AUTH } from '@/settings/RequestErrors'
import { useSelector } from '@/store/rootStore'

// Create Instances
const api = axios.create()

// Headers
export const setAuthHeader = (token: string) => {
	api.defaults.headers.common['Authorization'] = token
}

export const removeAuthHeader = () => {
	delete api.defaults.headers.common['Authorization']
}

export const setBaseUrl = (baseUrl: string) => {
	api.defaults.baseURL = baseUrl
}

// Interceptors
api.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		const notificationState = useSelector((state) => state.notification)
		const notificationHandlers = useNotification()

		if (
			error.response?.status &&
			error.response.status >= 500 &&
			!notificationState.value.open
		) {
			notificationHandlers.enqueueNotification({
				color: 'danger',
				message: 'Something went wrong. Try refreshing or contact support',
				delay: 8000,
			})
			Sentry.captureException(error)
		}
		return Promise.reject(error)
	}
)

const interceptorsInstance = {
	refreshToken: {} as { [key: string]: unknown },
}
export const setRefreshTokenInterceptor = () => {
	interceptorsInstance.refreshToken.ref = api.interceptors.response.use(
		(response: AxiosResponse) => {
			interceptorsInstance.refreshToken.retried = false
			return response
		},
		async (error: AxiosError) => {
			if (error.response && AUTH.includes(error.response.status)) {
				const { config } = error
				const { setNewAuthorization, removeAuthorization } = useAuthStore()
				const { userToken, refreshToken, requestToken } = useRefreshToken()
				if (
					!interceptorsInstance.refreshToken.retried &&
					localStorage.getItem('refreshToken')
				) {
					await requestToken({
						refresh_token: localStorage.getItem('refreshToken'),
						grant_type: 'refresh_token',
						client_id: import.meta.env.VITE_CRM_CLIENT_ID,
					})
					setNewAuthorization(userToken.value, refreshToken.value)
					if (config.headers) {
						config.headers['Authorization'] = userToken.value
					}
					interceptorsInstance.refreshToken.retried = true
					return api(config)
				} else {
					removeAuthorization()
					location.reload()
				}
			}
			return Promise.reject(error)
		}
	)
}

export const removeRefreshTokenInterceptor = () => {
	if (typeof interceptorsInstance.refreshToken.ref === 'number') {
		api.interceptors.response.eject(interceptorsInstance.refreshToken.ref)
	}
}

export default api
