import { nextTick } from 'vue'
import { Store } from 'vuex'
import { RootStore, StoreMutations } from '@/store/rootStore'

export type ScrollActivePageIntoView = () => Promise<void>

export type DomEvents = ScrollActivePageIntoView

export function useActivePageRef() {
	const getActivePageRef = () => document.querySelector('[data-page="active"]')

	async function afterDomUpdate(eventCallback: DomEvents) {
		await nextTick()
		eventCallback()
	}

	async function scrollActivePageIntoView(): ReturnType<ScrollActivePageIntoView> {
		const activePageRef = getActivePageRef()
		if (!activePageRef) return

		activePageRef.scrollIntoView({ behavior: 'smooth' })
	}

	return {
		getActivePageRef,
		afterDomUpdate,
		scrollActivePageIntoView,
	}
}

export function createPaginationPlugin() {
	const { afterDomUpdate, scrollActivePageIntoView } = useActivePageRef()

	return (store: Store<RootStore>) => {
		store.subscribe((rawMutation) => {
			const mutation: StoreMutations = {
				type: (rawMutation as any).type,
				payload: (rawMutation as any).payload?.payload,
			}

			switch (mutation.type) {
				case 'page/addPage':
					afterDomUpdate(scrollActivePageIntoView)
					break

				case 'page/deletePage':
					afterDomUpdate(scrollActivePageIntoView)
					break

				case 'page/duplicatePage':
					afterDomUpdate(scrollActivePageIntoView)
					break

				default:
					break
			}
		})
	}
}
