import { Page } from '@/store/interfaces'

export const dimensions = {
	width: 816,
	height: 1056,
}

const defaultPage: Omit<Page, 'project_id' | 'page_id' | 'index' | 'layout'> = {
	entity_type: 'page',
	layout_id: '',
	logos: {},
	products: {},
	status: 'active',
	created_at: '',
	updated_at: '',
	settings: {
		feature: {
			spreadPage: null,
		},
	},
	cover_image: '',
	title: '',
}

export const defaultFourProductsLayoutPage: Omit<
	Page,
	'project_id' | 'page_id' | 'index'
> = {
	...defaultPage,
	layout: {
		layout_id: '45a47a3f58d24a0a8861420a4a5fade4',
		logos_slots_quantity: 1,
		name: 'Basic four products',
		products_slots_quantity: 4,
	},
	layout_id: '45a47a3f58d24a0a8861420a4a5fade4',
}

export const defaultImageLayoutPage: Omit<
	Page,
	'project_id' | 'page_id' | 'index'
> = {
	...defaultPage,
	layout: {
		layout_id: '158b289101f54be9a691a022443f5c38',
		logos_slots_quantity: 0,
		name: 'Image page',
		products_slots_quantity: 0,
	},
	layout_id: '158b289101f54be9a691a022443f5c38',
}
