import { useLocalStorage } from '@zoomcatalog/composable'

const distributorStorage = useLocalStorage('distributorId')
const userToken = useLocalStorage('userToken')
const refreshToken = useLocalStorage('refreshToken')

export const storageInstances = {
	distributorStorage,
	userToken,
	refreshToken,
}
